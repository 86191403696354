<template lang="pug">
  .index-w
    <routerView/>
</template>

<script>
export default {};
</script>

<style lang="less" scoped></style>
